/** All .svg files in `icons/` get the `data-icon` attribute when imported with
webpack. Apply some default styling for icons on that attribute – but only if
the icon does not have a class. If you have added a class you get full
control of the styling. If you want to extend the default styling, use
`composes: icon from "../../main/css/icon.module.css";` (you might need to adjust
the path).
Based on FontAwesome’s inline SVG variant styling.
https://github.com/FortAwesome/Font-Awesome/blob/58afb4246eaaff2e23033d1687f15c4573c695c1/svg-with-js/css/fa-svg-with-js.css#L8-L13
*/
/*[data-icon]:not([class]),*/

.icon {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
  fill: currentColor;
  transform-origin: center;

  html[data-browser="ie"] & {
    width: 1em;
  }
}
