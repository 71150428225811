$border-width: 1px;
$padding: 16px 50px 16px 16px;
$gutter: 15px;
$border-radius: 3px;

.root {
  position: relative;
}

.field {
  position: relative;
  height: 60px;
  overflow: hidden;
  font-size: 16px;
  font-weight: $g-normal;
  background-color: $white;
  border: $border-width solid $grey30;
  border-radius: $border-radius;

  &.hasFocus {
    border-color: $textPrimary;
  }

  &.noSetHeight {
    height: auto;
  }
}

.hasError {
  border-color: $error10;
  border-radius: $border-radius $border-radius 0 0;
}

.hasWarning {
  border-color: $alert30;
  border-radius: $border-radius $border-radius 0 0;
}

.noLabel {
  margin-top: 22px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 22px;
}

.error {
  display: block;
  width: 100%;
  padding: 6px 16px;
  font-size: 15px;
  color: $textPrimary;
  color: $white;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $error10;
  border-radius: 0 0 $border-radius $border-radius;
}

.warning {
  position: absolute;
  bottom: -16px;
  font-size: 12px;
  line-height: 1em;
  color: $textPrimary;
  background-color: $alert30;
}

.input {
  width: 100%;
  height: 100%;
  padding: $padding;
  margin: 0;
  background: none;
  border: none;
  border-radius: none;

  &:read-only {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -$gutter;

  @media ($xsDown) {
    margin: 0;

    > * + * {
      margin-top: 10px;
    }
  }
}

.fieldWrapper {
  width: 50%;
  padding: $gutter;

  @media ($xsDown) {
    width: 100%;
    padding: 0;
  }
}

.noError {
  position: absolute;
  top: 50%;
  right: 18px;
  width: 18px;
  height: 18px;
  color: $textPrimary;
  transform: translateY(-50%);
}
