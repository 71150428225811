.root {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input {
  width: 100%;
  /** Add space for the button. */
  padding-right: 70px !important;

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

.button {
  position: absolute;
  top: 10px;
  right: 35px;
  padding: 8px;
}

.icon {
  font-size: 18px;
  color: $g-greyBen;
  composes: icon from "../../main/css/icon.module.css";
}
