/** These styles are shared between LoginForm, ResetPasswordForm and SignupForm. */

.form {
  width: 700px;
  max-width: 100%;
  margin: 0 auto;

  @media ($smDown) {
    width: 335px;
  }
}

.spaced > * + * {
  margin-top: 30px;
}

.spacedSmall > * + * {
  margin-top: 15px;
}

.spacedTiny > * + * {
  margin-top: 8px;
}

.center {
  text-align: center;
}

.fieldContainer {
  display: flex;
  justify-content: space-between;

  @media ($smDown) {
    flex-direction: column;
    justify-content: flex-start;

    > * + * {
      margin-top: 15px;
    }
  }
}

.field {
  width: 335px;

  @media ($xsDown) {
    width: 100%;
  }
}
