.root {
  line-height: 145%;

  &,
  > [data-i18n] {
    > * + * {
      margin-top: 1em;
    }

    > * + h2 {
      margin-top: 2.5em;
    }

    h1 {
      font-family: $g-fontFamilyAlternate;
      font-size: 32px;
      font-weight: $g-bold;
      line-height: 1;
      letter-spacing: 1.8px;
    }

    h2 {
      font-family: $g-fontFamilyAlternate;
      font-size: 20px;
      font-weight: $g-bold;
      line-height: 1;
      letter-spacing: 1.8px;
    }

    ul {
      padding-left: 0;

      > li {
        padding: 4px 0 4px 40px;
        margin-left: 20px;
        list-style: none;
        background: url("../../icons/new/icon-check-green@24x24.svg") no-repeat
          0 6px;
        background-size: 24px;

        @media ($xsDown) {
          margin-left: 0;
        }
      }
    }
  }

  &.limit {
    max-width: 768px;
  }

  &.paragraph12 {
    font-size: 12px;
    line-height: 24px;
  }

  &.paragraph14 {
    font-size: 14px;
    line-height: 24px;
  }

  &.paragraph16 {
    font-size: 16px;
    line-height: 24px;
  }

  &.paragraph18 {
    font-size: 18px;
    line-height: 32px;
  }

  &.monospace14 {
    font-family: $g-fontFamilyMono;
    font-size: 14px;
    line-height: 24px;
  }

  &.monospace16 {
    font-family: $g-fontFamilyMono;
    font-size: 14px;
    line-height: 24px;
  }

  &.bold {
    font-weight: $g-bold;
  }

  &.semibold {
    font-weight: $g-semiBold;
  }

  &.medium {
    font-weight: $g-semiBold;
  }

  &.regular {
    font-weight: $g-default;
  }

  &.black {
    color: $black;
  }

  &.white {
    color: $white;
  }

  &.primary {
    color: $textPrimary;
  }

  &.secondary {
    color: $textSecondary;
  }

  &.disabled {
    color: $textDisabled;
  }

  &.inherit {
    color: inherit;
  }

  &.center {
    text-align: center;
  }

  &:not(.coloredLinks) a {
    font-weight: $g-semiBold;
    color: inherit;
    text-decoration: none;

    &[href]:hover {
      text-decoration: underline;
    }
  }

  &.coloredLinks a {
    font-weight: $g-semiBold;
    color: $secondary10;
    text-align: left;
    text-underline-offset: 3px;

    &[href]:hover {
      text-decoration: underline;
    }
  }

  &.whiteLinks a {
    color: $white;

    &[href]:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  &.article {
    width: 100%;
    font-size: 16px;
    color: $textSecondary;
  }

  &.preamble {
    color: $textPrimary;

    @media ($xsDown) {
      margin-bottom: 30px;
    }
  }
}
