.bookingTimer {
  position: sticky;
  bottom: 0;
  z-index: $g-z-index-bookingTimer;
  padding: 15px 0;
  background-color: $white;
  border-top: 1px solid $g-greyLuke;

  @media ($xsDown) {
    font-size: 14px;
  }
}

.bookingTimerInner {
  position: relative;
}

.bookingTimerTime {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: $g-bold;
}
