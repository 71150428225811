.root {
  padding: 20px;
  background-color: $white;
  border: 1px solid $grey30;
}

.heading {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: $g-bold;
  color: $g-wine;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div {
    width: 100%;
  }

  > * + * {
    margin-top: 15px;
  }
}

.field {
  width: 100%;
}

.button {
  width: 100%;

  @media ($smDown) {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  .close {
    position: relative;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    margin-left: 12px;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      content: "";
      background: $grey30;
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}
