.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1em;
  font-weight: $g-semiBold;
  text-decoration: none;

  &.md {
    font-size: 16px;
  }

  &.lg {
    font-size: 18px;
  }

  * {
    flex-grow: 0;
  }
}

.svg {
  width: 14px;
  height: 100%;
  margin-left: 0.75em;

  &.leftFacing {
    margin-right: 0.75em;
    margin-left: 0;
    transform: rotateZ(-180deg);
  }
}
