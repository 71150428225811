.root {
  font-weight: $g-semiBold;
  color: $secondary10;
  text-align: left;
  text-underline-offset: 3px;

  &.primary {
    color: $primary10;
  }

  &.secondary {
    color: $secondary10;
  }

  &.textPrimary {
    color: $textPrimary;
  }

  &.white {
    color: $white;
  }

  a&[href]:hover,
  button&:not(:disabled):hover {
    text-decoration: underline;
  }

  a&:not([href]),
  button&:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
