.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $g-z-index-Modal;
  background-color: rgba(0, 0, 0, 0.5);
  transition-property: opacity;

  &.afterOpen {
    opacity: 1;
  }

  &,
  &.beforeClose {
    opacity: 0;
  }
}

.content {
  color: $textPrimary;
  transition-property: transform;

  &.afterOpen {
    transform: translateY(0);
  }

  &,
  &.beforeClose {
    transform: translateY(-100%);
  }
}
