.root {
  position: relative;
  display: inline-block;
}

.bar {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: currentColor;
  transition: transform 200ms ease-out;
}
