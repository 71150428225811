.root {
  min-height: $g-Button_base-height;
  padding: 0 10px;
  padding-right: 40px;
  margin: 0;
  line-height: 52px;
  color: $g-greyBoba;
  background: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9'%3E%3Cpolyline points='2,2 7,7 12,2' fill='none' stroke='%2331373e' stroke-width='2' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  border: 1px solid $grey30;
  border-radius: 0;
  appearance: none;

  > option {
    padding: 0;
  }

  &::-ms-expand {
    display: none;
  }

  &.rounded {
    border-color: $g-greyVader;
    border-radius: 4px;
  }

  &.grey {
    padding: 0 20px;
    font-size: 14px;
    font-weight: $g-bold;
    background-color: $g-greyChewie;
    border: none;
    border-radius: 4px;
  }

  &.red {
    padding: 0 20px;
    padding-right: 50px;
    color: $textPrimary;
    border-color: $g-red;
  }

  &.secondary {
    padding: 0 20px;
    padding-right: 50px;
    color: $textPrimary;
    border-color: $secondary10;
  }
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 22px;
}
