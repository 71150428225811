.root {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: $white;
}

.close {
  position: absolute;
  top: 0;
  right: 60px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 100%;

  @media ($xsDown) {
    right: 0;
  }
}

.closeIcon {
  color: $primary10;
  composes: icon from "../../main/css/icon.module.css";
}

.logoWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: $g-navHeight;
  border-bottom: 1px solid $g-borderColor;
}

.logo {
  position: absolute;
  left: 50%;
  height: 27.5px;
  padding: 3px 15px;
  transform: translateX(-50%);
}

.inner {
  padding: 50px 0;

  @media ($xsDown) {
    padding: 30px 0;
  }
}
