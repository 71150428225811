$checkboxSize: 30px;

.terms {
  position: relative;
  font-size: 14px;

  /** Draw an arrow from the error message to the checkbox. */
  &.hasError {
    $top: 54px;
    $bottom: 8px;

    &::before,
    &::after {
      position: absolute;
      left: 10px;
      pointer-events: none;
      content: "";
      border: 3px solid $error10;
    }

    &::before {
      bottom: $bottom;
      width: 13px;
      height: calc(100% - $top);
      border-top: none;
      border-right: none;
      border-bottom-left-radius: 8px;
    }

    &::after {
      $size: 10px;
      top: calc($top - $bottom);
      width: $size;
      height: $size;
      border-right: none;
      border-bottom: none;
      transform: rotate(45deg);
      transform-origin: top center;
    }
  }

  > * {
    position: relative;
    padding-left: calc($checkboxSize + 12px);

    + * {
      margin-top: 0;
    }
  }
}

.termsLabel {
  display: flex;
}

.termsCheckbox {
  position: absolute;
  top: 8px;
  left: 0;
}

.termsError {
  font-size: 16px;
  font-weight: $g-bold;
  color: $error10;
}
