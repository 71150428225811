$sidePadding: 24px;
$iconSize: 18px;

.root {
  position: relative;
  display: inline-block;
  min-height: $g-Button_base-height;
  padding: 10px $sidePadding;
  font-size: 16px;
  color: $textPrimary;
  text-align: center;

  &.primary {
    color: $white;
    background-color: $primary10;
    border-color: $primary10;

    &:not([disabled]):hover {
      background-color: $primary20;
    }

    &:not([disabled]):active {
      filter: brightness(85%);
    }
  }

  &.secondary {
    color: $white;
    background-color: $secondary10;
    border-color: $secondary10;

    &:not([disabled]):hover {
      background-color: $secondary20;
    }

    &:not([disabled]):active {
      filter: brightness(85%);
    }
  }

  &.cancel {
    color: $white;
    background-color: $error10;
    border-color: $error10;

    &:not([disabled]):hover {
      background-color: $error12;
    }

    &:not([disabled]):active {
      filter: brightness(85%);
    }
  }

  &.tertiary {
    color: $textPrimary;
    background-color: $grey30;
    border-color: $grey30;

    &:not([disabled]):hover {
      background-color: $grey35;
    }

    &:not([disabled]):active {
      filter: brightness(85%);
    }
  }

  a&:not([href]),
  button&:disabled {
    color: $g-greyKylo;
    cursor: not-allowed;
  }
}

.outlined {
  padding: 10px $sidePadding;
  font-weight: $g-bold;
  background-color: $white;
  border-style: solid;
  border-width: 1px;

  a&:not([href]),
  button&:disabled {
    color: $g-greyKylo;
    cursor: not-allowed;
    border-color: $grey30;
  }
}

.contained {
  font-weight: $g-bold;
  color: $white;

  a&:not([href]),
  button&:disabled {
    color: $g-greyKylo;
    cursor: not-allowed;
    background-color: $grey30;
    border: none;
  }
}

.big {
  min-height: 64px;
  padding: 18px $sidePadding;
  line-height: 150%;
}

.block {
  display: block;
  width: 100%;
}

.withIcon {
  display: flex;
  justify-content: center;

  > span {
    padding-right: calc($iconSize * 2);
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: $sidePadding;
  font-size: $iconSize;
  color: inherit;
  transform: translateY(-50%);
  composes: icon from "../../main/css/icon.module.css";
}

.noBorder {
  border: none;
  border-radius: 0;
}
