.root {
  border-radius: 3px;
}

.faceUp {
  border-radius: 16px 16px 0 0;
}

.faceRight {
  border-radius: 0 16px 16px 0;
}

.faceDown {
  border-radius: 0 0 16px 16px;
}

.faceLeft {
  border-radius: 16px 0 0 16px;
}
