/* OLD  ( POSSIBLY STILL USED IN BANANAS ADMIN ): */
@font-face {
  font-family: "Museo Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/_next/static/public/static/fonts/MuseoSlab-regular.woff2") format("woff2"),
    url("/_next/static/public/static/fonts/MuseoSlab-regular.woff") format("woff");
}

@font-face {
  font-family: "Museo Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/_next/static/public/static/fonts/MuseoSlab-bold.woff2") format("woff2"),
    url("/_next/static/public/static/fonts/MuseoSlab-bold.woff") format("woff");
}

@font-face {
  font-family: "Museo Slab";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/_next/static/public/static/fonts/MuseoSlab-black.woff2") format("woff2"),
    url("/_next/static/public/static/fonts/MuseoSlab-black.woff") format("woff");
}

/* REBRAND FONTS */

/* MTRX CUSTOM FONTS */
@font-face {
  font-display: block;
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 400;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "SuisseIntl";
  font-style: italic;
  font-weight: 400;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-RegularItalic-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-RegularItalic-WebS.woff")
      format("woff");
}

@font-face {
  font-display: block;
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 500;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 600;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-SemiBold-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-SemiBold-WebS.woff") format("woff");
}
/* TODO: Find the correct font files
@font-face {
  font-display: block;
  font-family: "SuisseIntl";
  font-style: italic;
  font-weight: 600;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic-WebS.woff")
      format("woff");
} */

@font-face {
  font-display: block;
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 700;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-Bold-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntl-Bold-WebS.woff") format("woff");
}

/* TODO: Find the correct font files
@font-face {
  font-display: block;
  font-family: "SuisseIntlMono";
  font-style: normal;
  font-weight: 400;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntlMono-Regular-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntlMono-Regular-WebS.woff")
      format("woff");
} */

@font-face {
  font-display: block;
  font-family: "SuisseIntlMono";
  font-style: normal;
  font-weight: 700;
  src: url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntlMono-Bold-WebS.woff2")
      format("woff2"),
    url("/_next/static/public/static/fonts/SuisseIntl/SuisseIntlMono-Bold-WebS.woff") format("woff");
}

body {
  position: relative;
  height: 100%;
  min-height: calc(100 * var(--vh, 1vh));
  margin: 0;
}

#__next {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100 * var(--vh, 1vh));
}

.tooltip.localized-message-tooltip {
  max-width: 400px;
  font-size: 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

[tabindex="-1"] {
  outline: none !important;
}

svg:not(:root) {
  overflow: visible;
}

html {
  height: 100%;
  font-family: $g-fontFamily;
  font-size: $g-fontSize;
  font-weight: $g-default;
  line-height: 145%;
  color: $textPrimary;
  /** This is the iOS overscroll color (same as header and footer). The real page
  background color is set in the <Layout> component.*/
  background-color: $g-pageBackgroundColor;
  text-rendering: optimizeLegibility;

  /** global custom property fallback for the javascript setting the actual vh. */
  --vh: 1vh;

  &[data-browser="ie"] {
    color: $textPrimary;
    background-color: $g-pageBackgroundColor;
  }

  &[data-mode="app"] {
    user-select: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 1em;
  font-weight: inherit;
  line-height: inherit;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;

  > li {
    list-style: none;
  }
}

dl {
  margin: 0;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;

  &:-moz-ui-invalid {
    box-shadow: none;
  }

  &:required:invalid {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

[data-icon]:not([class]) {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
  fill: currentColor;
  transform-origin: center;

  html[data-browser="ie"] & {
    width: 1em;
  }
}

button {
  padding: 0;
  margin: 0;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;
  appearance: none;
}

textarea {
  width: 100%;
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

*::placeholder {
  color: inherit;
  opacity: 0.3;
}

label {
  margin: 0;
  font-weight: inherit;
}

[type="search"] {
  outline-offset: -2px;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: textfield;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  font-weight: inherit;
  text-align: left;
}

a {
  color: inherit;
  text-decoration: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

figure {
  margin: 0;
}

pre {
  margin: 0;
  overflow: auto;
}

address {
  font-style: inherit;
}

strong,
b {
  font-weight: $g-bold;
}

hr {
  margin-bottom: 1em;
  border: none;
  border-top: 1px solid $g-borderColor;
}

/** Move the Djedi sidebar button to the bottom-right corner, since it covers too
 much of the menu in the top-right corner. */
#djedi-cms {
  top: auto !important;
  bottom: 0 !important;
  /** This is the maximum possible z-index value and is needed to beat the
   Freshchat widget. */
  z-index: 2147483647 !important;
}

/** Move Freshchat slightly closer to the window edges, so it doesn’t appear to
awkardly stick out the top of the cookie bar. */

#fc_frame,
#fc_frame.fc-widget-normal {
  right: 5px !important;
  bottom: 2px !important;
}

#nprogress {
  $color: $primary20;
  pointer-events: none;

  .bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $g-z-index-Nprogress;
    width: 100%;
    height: 6px;
    background: $color;
  }

  .peg {
    position: absolute;
    right: 0;
    display: block;
    width: 100px;
    height: 100%;
    opacity: 1;
    transform: rotate(3deg) translate(0, -4px);
  }
}
