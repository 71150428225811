.root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $g-z-index-Drawer;
  width: 100%;
  height: 100vh;
  height: calc(100 * var(--vh));
  background: white;
  opacity: 0;
}

.animated {
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1),
    opacity 0.15s ease-out;
}

.headingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 61px;
  padding: 3px 15px;
  transform: translate(-50%, -50%);

  .svg {
    height: 100%;
  }
}

.left {
  transform: translateX(-100%);
}

.bottom {
  transform: translateY(100%);
}

.top {
  transform: translateY(-100%);
}

.right {
  transform: translateX(100%);
}

.open {
  opacity: 1;
  transform: translate(0, 0);
}
