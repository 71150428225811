.root {
  $size: 30px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: $size;
  height: $size;
  cursor: pointer;

  &.whiteRoot {
    $size: 30px;
    width: $size;
    height: $size;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.checkIcon {
  $iconSize: 20px;
  width: $iconSize;
  height: $iconSize;
  composes: icon from "../../main/css/icon.module.css";
}

.checkbox {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $secondary10;
  border: 2px solid $secondary10;

  svg {
    transition: transform 200ms;
    transform: scale(0) rotate(-25deg);
  }

  .input:checked + & {
    svg {
      transform: scale(1) rotate(0deg);
    }
  }

  .input:disabled + & {
    opacity: 0.65;
  }

  &.whiteCheckbox {
    color: $textPrimary;
    background-color: $white;
    border: 1px solid $grey30;
    border-radius: 5px;
  }
}
