$border: 1px solid $grey30;

.root {
  position: relative;
  display: flex;
  align-items: stretch;
  max-width: 668px;
  margin-bottom: 1px;
  overflow: hidden;
  background: white;
  border: $border;
  border-radius: 3px 0 0 3px;

  &.noButton > * {
    @media ($smUp) {
      width: calc(100% / 2);
    }
  }

  & > * + * {
    border-left: $border;

    @media ($xsDown) {
      border-top: $border;
      border-left: none;
    }
  }

  @media ($xsDown) {
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    background: transparent;
    border: none;
  }
}

.selectWrapper {
  position: relative;
  display: flex;
  flex: 1;
  width: calc(100% - 100% / 3);

  > * + * {
    @media ($xsDown) {
      border: $border;
    }
  }

  .selectRight {
    border-left: $border;

    @media ($xsDown) {
      border-top: none;
    }
  }

  @media ($xsDown) {
    flex-direction: column;
    width: 100%;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  padding: 16px 32px;
  background: white;
  border-left: $border;

  @media ($smDown) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media ($xsDown) {
    width: 100%;
    padding: 0;
    margin: 0;
    background: transparent;
    border-left: none;

    & > div {
      width: 100%;
    }
  }
}

.hideButton {
  display: none;
}

$basePadding: 22px;
$selectPaddingTop: 24px;
$selectPaddingLeft: 36px;
$labelMargin: 4px;
$labelSize: 15px;

.select {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  width: 100%;
  height: 82px;
  padding: $basePadding;
  padding-top: $selectPaddingTop;
  padding-left: $selectPaddingLeft;
  background: white;

  /** Prevent jumping in Firefox when selecting a new option. */
  @media ($smUp) {
    flex-basis: calc(100% / 2);
  }
}

.selectInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $basePadding;
  padding-top: calc($selectPaddingTop + $labelSize);
  padding-left: $selectPaddingLeft;
  line-height: 1em;
  background: none;
  border: none;
  --webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
  }
}

.selectLabel {
  margin-bottom: $labelMargin;
  font-size: $labelSize;
  line-height: 1em;
  color: $grey30;
}

.swapButton {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: $primary10;
  cursor: pointer;
  background: white;
  border: $border;
  border-radius: 3px;
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;

  @media ($xsDown) {
    color: $primary30;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.searchButton {
  flex-grow: 1;
  margin-top: 0;

  @media ($xsDown) {
    /** hide border on Select */
    margin-top: -1px;
  }
}
