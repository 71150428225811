/** The height is smaller on mobile. That’s set in ./index.js. */
$userButton_size: 30px;
$upperItem_padding-horizontal: 15px;

.navRoot {
  display: flex;
  flex-direction: column;
}

.scrollAway {
  position: sticky;
  top: 0;
  z-index: $g-z-index-Nav;
  width: 100%;
  transition: transform 0.016s, linear; /** roughly equal to the polling frequency of the listener. 60fps, seems to smoothen stuff out a bit */
}

.desktopNav {
  position: sticky;
  top: 0;
  z-index: $g-z-index-Nav;
  width: 100%;
}

.nav {
  z-index: $g-z-index-Nav;
  display: flex;
  flex-direction: column;
  order: 1;
}

.navChildren {
  order: 3;
}

.hero {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.logoWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 61px;
  padding: 3px 15px;
  transform: translate(-50%, -50%);

  .logo {
    height: 100%;
  }
}

.navInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  background: $g-greyR2;
}

.topWrapper {
  padding: 60px 150px 60px 150px;
  background-color: $white;

  @media ($xsDown) {
    padding: 24px;
  }
}

.bottomWrapper {
  padding: 60px 150px 150px 150px;
  margin-bottom: 100px;

  @media ($xsDown) {
    padding: 24px;
  }
}

.languageSwitch {
  @media ($smDown) {
    display: none;
  }

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin-right: 8px;
    font-size: 14px;
    border: 1px solid $primary20;
    border-radius: 3px;
  }
}

.hamburgerLanguageSwitch {
  font-size: 18px;

  a {
    color: $textPrimary;
  }
}

.rightNav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkItem {
  display: flex;
  align-items: center;
  padding: 12px 15px 14px 0;
  font-size: 32px;
  font-weight: $g-semiBold;
  color: $secondary10;

  &:hover {
    text-decoration: underline;
  }

  @media ($xsDown) {
    padding: 5px 15px 5px 0;
    font-size: 21px;
  }
}

.myMtrxLogo {
  height: 27.5px;
}

.myMtrxLinks,
.clubxLinks {
  > * {
    display: block;
    margin-top: 12px;
  }
}

.logoutWrapper {
  padding-top: 20px;
  margin-top: 20px;

  @media ($smDown) {
    border-top: 2px solid $g-borderColor;
  }
}
