$userButton_size: 30px;

.userButton {
  height: 38px;
  background: none;
}

.userButtonInner {
  /* composes: regular from "../../../../Button/styles.module.css"; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px 15px;
  color: $primary20;
  background: none;
  border: 1px solid $primary20;
  border-radius: 3px;

  &.loggedOut {
    color: $textPrimary;
  }

  svg {
    width: 16px;
  }

  > * + * {
    margin-left: 6px;
  }

  @media ($xsDown) {
    width: $userButton_size;
    height: $userButton_size;
    padding: 0;
    border: none;
    border-radius: 0;
  }
}

.loggedInState {
  font-size: 14px;

  @media ($xsDown) {
    display: none;
  }
}

.myMtrxIcon {
  margin-right: 7.5px;
  transform: scale(2);

  @media ($xsDown) {
    margin-right: 0;
  }
}
