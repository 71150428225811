.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 20px;

  > * + * {
    margin-top: 30px;
  }
}

.text {
  font-weight: $g-bold;
  text-align: center;

  @media ($smUp) {
    font-size: 20px;
  }
}

.buttons {
  display: flex;

  > * + * {
    margin-left: 15px;
  }

  @media ($xsDown) {
    flex-direction: column;

    > * + * {
      margin-top: 15px;
      margin-left: 0;
    }
  }
}
