$background: $white;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.navChildren {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $g-navHeight;
  padding: 0 18px;
  color: $primary20;
  background-color: $background;
  border-bottom: 1px solid $grey30;

  @media ($xsUp) {
    padding: 0 56px;
  }
}
