$button-width: 50px;
$overlap: 20px;
$padding-vertical: 15px;

.root {
  position: fixed;
  top: calc($overlap * -1);
  right: 0;
  left: 0;
  z-index: $g-z-index-TopNotification;
  display: flex;
  align-items: center;
  justify-content: center;
  /* stylelint-disable-next-line function-calc-no-invalid*/
  padding: calc($padding-vertical + $overlap) $button-width $padding-vertical;
  color: $textPrimary;
  background-color: $alert20;
  /** One-off colors for this component. */
  /** https://easings.net/#easeOutBack */
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-property: opacity, transform;

  @media ($xsDown) {
    padding-left: 15px;
  }

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}

.hidden {
  opacity: 0;
  transition-timing-function: ease-in;
  transform: translateY(calc(-100% + 10px));
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  width: $button-width;
  height: 100%;
  padding-top: $overlap;
  font-size: 27px;
}
