.root {
  box-sizing: content-box;
  padding: 0 24px;
  margin: 0 auto;

  @media ($smDown) {
    padding: 0 16px;
  }
}

.fullHeight {
  height: 100%;
}
