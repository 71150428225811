$title-height: 82px;
$title-height-small: 48px;
$space: 30px;
$space-small: 15px;

$icon-size: 20px;

.root {
  position: absolute;
  top: 50vh;
  left: 50vw;
  z-index: 1;
  width: 335px;
  max-width: calc(100vw - $space * 2);
  max-height: calc(100vh - $space * 2);
  padding: 15px;
  color: $g-greyKylo;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);

  @media ($xsDown) {
    /* stylelint-disable-next-line function-calc-no-invalid*/
    max-width: calc(100vw - $space-small * 2);
  }
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $space-small;
}

.onRequestCloseButton {
  width: 100%;
  color: $g-greyVader;
  background-color: $white;

  button&:not(:disabled):hover {
    background-color: $white;
  }
}

.confirmButton {
  width: 100%;
  font-weight: $g-bold;
  text-align: center;
  text-decoration: underline;
}

.titleBar {
  font-weight: $g-semiBold;
  color: $textPrimary;
}

.buttons {
  display: flex;
  gap: 8px;

  > * {
    flex: 1;
  }
}
